import React from "react";
import api from "../services/api";
import formatMoney from "../utils/formatMoney";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
  imageList: {
    display: "flex",
  },
  image: {
    maxHeight: 200,
  },
}));

function View({ match, history }) {
  const classes = useStyles();
  const [store, setStore] = React.useState(null);
  const [products, setProducts] = React.useState([]);

  function fetchContent() {
    api.get(`/stores/${match.params.storeId}`).then((response) => {
      setStore(response.data);
    });

    api.get(`/products`).then((response) => {
      setProducts(
        response.data.filter(
          (product) => product.store_id === parseInt(match.params.storeId, 10)
        )
      );
    });
  }

  function editStore() {
    history.push(`/stores/${match.params.storeId}/edit`);
  }

  function enablePayment() {
    if (store) {
      api.get(`store-payment/${store.id}`).then((response) => {
        window.open(response.data.redirectURL, "_self");
      });
    }
  }

  // function deleteStore() {
  //   if (
  //     window.confirm(`Tem certeza que deseja excluir a loja ${store?.name}?`)
  //   ) {
  //     api.delete(`/stores/${match.params.storeId}`).then(() => {
  //       alert("Loja excluída com sucesso!");
  //     });
  //   }
  // }

  function openProduct(product) {
    history.push(`/products/${product.id}`);
  }

  React.useEffect(fetchContent, [match.params.storeId]);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        {/* <Grid item>
          <Button variant="contained" onClick={deleteStore}>
            Excluir Loja
          </Button>
        </Grid> */}

        {store && !store.pagseguro_public_key && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={enablePayment}>
              Ativar Pagamento
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button variant="contained" color="primary" onClick={editStore}>
            Editar Loja
          </Button>
        </Grid>
      </Grid>

      {store && (
        <>
          <Typography variant="h4" component="h2" gutterBottom>
            {store.name}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <b>Descrição:</b> {store.description}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Taxa:</b> {store.fee_per_sale * 100}%
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Pagamento:</b>{" "}
            {store.pagseguro_public_key
              ? "Ativo com PagSeguro"
              : "Não configurado"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Ultima atualização:</b>{" "}
            {dayjs(store.updated_at).format("DD/MM/YYYY")}
          </Typography>
        </>
      )}

      <Typography variant="h5" component="h2" gutterBottom>
        Produtos
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Preço (R$)</TableCell>
              <TableCell>REF</TableCell>
              <TableCell>Última alteração</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow
                key={product.id}
                className={classes.tableRow}
                onClick={() => openProduct(product)}
                hover
              >
                <TableCell component="th" scope="row">
                  {product.id}
                </TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell align="right">
                  {formatMoney(product.price)}
                </TableCell>
                <TableCell>{product.reference}</TableCell>
                <TableCell>
                  {dayjs(product.updated_at).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Shell>
  );
}

export default View;
