import React from "react";
import api from "../services/api";
import dayjs from "dayjs";

import Shell from "../components/Shell";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "space-between",
    marginBottom: 16,
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: "pointer",
  },
}));

function View({ history }) {
  const classes = useStyles();
  const [stores, setStores] = React.useState([]);

  function fetchContent() {
    api.get(`/stores`).then((response) => {
      setStores(response.data);
    });
  }

  function createNewStore() {
    history.push(`/stores/new`);
  }

  function openListItem(item) {
    history.push(`/stores/${item.id}`);
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Typography variant="h5" component="h2" gutterBottom>
            Lojas
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={createNewStore}>
            Adicionar Loja
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Taxa</TableCell>
              <TableCell>Pagamento</TableCell>
              <TableCell>Última alteração</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stores.map((store) => (
              <TableRow
                key={store.id}
                className={classes.tableRow}
                onClick={() => openListItem(store)}
                hover
              >
                <TableCell component="th" scope="row">
                  {store.id}
                </TableCell>
                <TableCell>{store.name}</TableCell>
                <TableCell>{store.fee_per_sale * 100}%</TableCell>
                <TableCell>
                  {store.pagseguro_public_key ? "Ativo" : "Inativo"}
                </TableCell>
                <TableCell>
                  {dayjs(store.updated_at).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Shell>
  );
}

export default View;
