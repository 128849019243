import React from "react";
import api from "../services/api";
import { makeStyles } from "@material-ui/core/styles";
import { useFormState } from "react-use-form-state";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
}));

function View({ match, history }) {
  const classes = useStyles();
  const [store, setStore] = React.useState(null);
  const [formState, { text, number }] = useFormState();

  function fetchContent() {
    api.get(`/stores/${match.params.storeId}`).then((response) => {
      setStore(response.data);

      formState.setField("name", response.data.name);
      formState.setField("description", response.data.description);
      formState.setField("fee_per_sale", response.data.fee_per_sale * 100);
      formState.setField("directors", response.data.directors);
      formState.setField("contacts", response.data.contacts);
      formState.setField("email", response.data.email);
      formState.setField("cnpj", response.data.cnpj);
      formState.setField("observations", response.data.observations);
      formState.setField("bank_id", response.data.bank_id);
      formState.setField("bank_agency", response.data.bank_agency);
      formState.setField("bank_account", response.data.bank_account);
      formState.setField("street", response.data.street);
      formState.setField("number", response.data.number);
      formState.setField("neighborhood", response.data.neighborhood);
      formState.setField("city", response.data.city);
      formState.setField("state", response.data.state);
      formState.setField("postal_code", response.data.postal_code);
    });
  }

  function saveStore() {
    const data = {
      name: formState.values.name,
      description: formState.values.description,
      fee_per_sale: formState.values.fee_per_sale / 100,
      directors: formState.values.directors,
      contacts: formState.values.contacts,
      email: formState.values.email,
      cnpj: formState.values.cnpj,
      observations: formState.values.observations,
      bank_id: formState.values.bank_id,
      bank_agency: formState.values.bank_agency,
      bank_account: formState.values.bank_account,
      street: formState.values.street,
      number: formState.values.number,
      neighborhood: formState.values.neighborhood,
      city: formState.values.city,
      state: formState.values.state,
      postal_code: formState.values.postal_code,
    };

    api
      .put(`/stores/${match.params.storeId}`, data)
      .then(() => {
        alert("Loja salva com sucesso!");
        history.push(`/stores/${match.params.storeId}`);
      })
      .catch(() => {
        alert("Ocorreu um erro, confira os dados e tente novamente");
      });
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={saveStore}>
            Salvar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            Editando loja: {store?.name}
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Informações
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            {...text("name")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            multiline
            {...text("description")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Taxa por venda (%)"
            variant="outlined"
            fullWidth
            multiline
            {...number("fee_per_sale")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Diretores"
            variant="outlined"
            fullWidth
            {...text("directors")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Contatos"
            variant="outlined"
            fullWidth
            {...text("contacts")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            {...text("email")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="CNPJ"
            variant="outlined"
            fullWidth
            {...text("cnpj")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Observações"
            variant="outlined"
            fullWidth
            multiline
            {...text("observations")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Código do Banco"
            variant="outlined"
            fullWidth
            {...text("bank_id")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Agência (sem dígito)"
            variant="outlined"
            fullWidth
            {...text("bank_agency")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Conta corrente (com dígito)"
            variant="outlined"
            fullWidth
            {...text("bank_account")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="CEP"
            variant="outlined"
            fullWidth
            {...text("postal_code")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Rua"
            variant="outlined"
            fullWidth
            {...text("street")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Número"
            variant="outlined"
            fullWidth
            {...text("number")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Bairro"
            variant="outlined"
            fullWidth
            {...text("neighborhood")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Cidade"
            variant="outlined"
            fullWidth
            {...text("city")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Estado"
            variant="outlined"
            fullWidth
            {...text("state")}
          />
        </Grid>
      </Grid>
    </Shell>
  );
}

export default View;
