import React from "react";
import api from "../services/api";
import { storage } from "../services/firebase";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyInput from "react-currency-input";
import { useFormState } from "react-use-form-state";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ImageUploader from "react-images-upload";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
}));

function MoneyInput(props) {
  const { onChange, inputRef, ...other } = props;

  return (
    <CurrencyInput
      {...other}
      getInputRef={inputRef}
      onChangeEvent={(event, maskedvalue, floatvalue) =>
        onChange({ target: { value: floatvalue, name: props.name } })
      }
      decimalSeparator=","
      thousandSeparator="."
      prefix="R$ "
      inputType="number"
    />
  );
}

function View({ match, history }) {
  const classes = useStyles();
  const [categories, setCategories] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [formState, { text, select, number }] = useFormState();
  const [sizes, setSizes] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function fetchContent() {
    api.get(`/stores`).then((response) => {
      setStores(response.data);
    });

    api.get(`/categories`).then((response) => {
      setCategories(response.data);
    });
  }

  function handleImageDrop(picture) {
    setImages([...images, picture]);
  }

  function addNewSize() {
    setSizes((options) => [
      ...options,
      { id: `temp-${Date.now()}`, label: "", price: formState?.values?.price },
    ]);
  }

  function setSizeField(id, field, value) {
    setSizes((sizes) =>
      sizes.map((size) => (size.id === id ? { ...size, [field]: value } : size))
    );
  }

  function addNewColor() {
    setColors((options) => [
      ...options,
      { id: `temp-${Date.now()}`, label: "" },
    ]);
  }

  function setColorField(id, field, value) {
    setColors((colors) =>
      colors.map((color) =>
        color.id === id ? { ...color, [field]: value } : color
      )
    );
  }

  async function saveProduct() {
    if (loading) return;

    try {
      setLoading(true);

      const imageList = images[images.length - 1];

      if (imageList.length < 1) {
        console.log("Adicione mais imagens");
        return;
      }

      const imageURLs = await Promise.all(
        imageList.map(async (file) => {
          const imageRef = storage.ref(`products/${Date.now()}`);
          const imageSnapshot = await imageRef.put(file);
          const imageURL = `https://firebasestorage.googleapis.com/v0/b/${imageSnapshot.metadata.bucket}/o/products%2F${imageSnapshot.metadata.name}?alt=media`;
          return { src: imageURL };
        })
      );

      const data = {
        name: formState.values.name,
        price: parseInt(parseFloat(formState.values.price, 10) * 100, 10),
        description: formState.values.description,
        reference: formState.values.reference,
        gender: formState.values.gender,
        grid: formState.values.grid,
        materials: formState.values.materials,
        grid_quantity: formState.values.grid_quantity,
        store_id: formState.values.store_id,
        category_id: formState.values.category_id,
        colors: colors.map(({ label }) => ({ label })),
        sizes: sizes.map(({ label, price }) => ({
          label,
          price: parseInt(parseFloat(price, 10) * 100, 10),
        })),
        thumbnail: imageURLs[0].src,
        images: imageURLs,
      };

      await api.post(`/products`, data);

      alert("Produto criado com sucesso!");

      setLoading(false);
      history.push(`/products`);
    } catch (error) {
      setLoading(false);
      alert("Ocorreu um erro ao salvar, confira os dados e tente novamente");
      console.log(error);
    }
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={saveProduct}>
            Salvar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Informações
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            {...text("name")}
          />
        </Grid>

        <Grid item sm={12} lg={4}>
          <TextField
            label="Preço base"
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: MoneyInput,
            }}
            {...text({
              name: "price",
              validate: (value) => parseInt(value, 10) >= 0,
            })}
          />
        </Grid>

        <Grid item sm={12} lg={4}>
          <TextField
            label="Referência"
            variant="outlined"
            fullWidth
            {...text("reference")}
          />
        </Grid>

        <Grid item sm={12} lg={4}>
          <TextField
            label="Quantidade de produtos na grade"
            variant="outlined"
            fullWidth
            {...number("grid_quantity")}
          />
        </Grid>

        <Grid item sm={12} lg={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel id="category-selection">Categoria</InputLabel>
            <Select
              labelId="category-selection"
              label="Categoria"
              {...select({ name: "category_id", validate: (value) => !!value })}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={12} lg={3}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel id="store-selection">Gênero</InputLabel>
            <Select
              labelId="store-selection"
              label="Loja"
              {...select({ name: "gender", validate: (value) => !!value })}
            >
              <MenuItem value="f">Feminino</MenuItem>
              <MenuItem value="m">Masculino</MenuItem>
              <MenuItem value="nd">Neutro</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={12} lg={5}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel id="store-selection">Loja</InputLabel>
            <Select
              labelId="store-selection"
              label="Loja"
              {...select({ name: "store_id", validate: (value) => !!value })}
            >
              {stores.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={12} lg={6}>
          <TextField
            label="Grade"
            variant="outlined"
            fullWidth
            {...text("grid")}
          />
        </Grid>

        <Grid item sm={12} lg={6}>
          <TextField
            label="Composição"
            variant="outlined"
            fullWidth
            {...text("materials")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            multiline
            {...text("description")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Cores
          </Typography>
        </Grid>

        {colors?.map((color, index) => (
          <Grid item sm={12} md={4} key={color.id}>
            <TextField
              label={`Cor ${index + 1}`}
              variant="outlined"
              fullWidth
              value={color.label}
              onChange={(event) =>
                setColorField(color.id, "label", event.target.value)
              }
            />
          </Grid>
        ))}

        <Grid item>
          <Button variant="contained" color="primary" onClick={addNewColor}>
            Criar nova cor
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Tamanhos
          </Typography>
        </Grid>

        {sizes?.map((size, index) => (
          <Grid item container spacing={2} key={size.id}>
            <Grid item sm={6}>
              <TextField
                label={`Tamanho ${index + 1}`}
                variant="outlined"
                fullWidth
                value={size.label}
                onChange={(event) =>
                  setSizeField(size.id, "label", event.target.value)
                }
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                label={`Preço ${index + 1}`}
                variant="outlined"
                fullWidth
                value={size.price}
                onChange={(event) =>
                  setSizeField(size.id, "price", event.target.value)
                }
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            </Grid>
          </Grid>
        ))}

        <Grid item>
          <Button variant="contained" color="primary" onClick={addNewSize}>
            Criar novo tamanho
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Imagens
          </Typography>
        </Grid>

        <ImageUploader
          withIcon={true}
          onChange={handleImageDrop}
          imgExtension={[".jpg", ".jpeg", ".png"]}
          maxFileSize={5 * 1024 * 1024}
          label="Tamanho máximo da imagem: 5mb, formatos: jpg, jpeg ou png"
          buttonText="Escolher imagem"
          withPreview
          // defaultImages={product?.images?.map((image) => image.src)}
        />
      </Grid>
    </Shell>
  );
}

export default View;
