import React from "react";
import { useFormState } from "react-use-form-state";
import api from "../services/api";
import { saveAuthData } from "../services/auth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function View({ history, location }) {
  const classes = useStyles();

  const [formState, { email, password }] = useFormState();

  function handleSubmit(event) {
    event.preventDefault();

    const data = formState.values;

    api
      .post(`/sessions`, data)
      .then((response) => {
        if (response.data?.user?.stores?.length) {
          saveAuthData(response.data);
          history.push(location?.state?.from ?? "/");
        } else {
          alert("Você não tem acesso ao painel de administrador");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("Usuário ou senha incorretos, por favor verifique");
        } else {
          alert("Ocorreu um erro ao fazer login, tente novamente");
        }
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Painel do Lojista Compre Nosso
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            {...email("email")}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            {...password("password")}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default View;
