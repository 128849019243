import React from "react";
import api from "../services/api";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
  imageList: {
    display: "flex",
  },
  image: {
    maxHeight: 200,
  },
}));

function View({ match, history }) {
  const classes = useStyles();
  const [category, setCategory] = React.useState(null);

  function fetchContent() {
    api.get(`/categories/${match.params.categoryId}`).then((response) => {
      setCategory(response.data);
    });
  }

  function createNewCategory() {
    history.push(`/categories/${match.params.categoryId}/edit`);
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={createNewCategory}
          >
            Editar Categoria
          </Button>
        </Grid>
      </Grid>

      {category && (
        <>
          <Typography variant="h4" component="h2" gutterBottom>
            {category.name}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <b>Descrição:</b> {category.description}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Ultima atualização:</b>{" "}
            {dayjs(category.updated_at).format("DD/MM/YYYY")}
          </Typography>
        </>
      )}
    </Shell>
  );
}

export default View;
