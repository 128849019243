import React from "react";
import api from "../services/api";
import { makeStyles } from "@material-ui/core/styles";
import { useFormState } from "react-use-form-state";
import { storage } from "../services/firebase";
import cep from "cep-promise";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ImageUploader from "react-images-upload";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
}));

function View({ match, history }) {
  const classes = useStyles();
  const [formState, { text, number }] = useFormState({ fee_per_sale: 10 });
  const [image, setImage] = React.useState(null);

  function handleImageDrop(picture) {
    setImage(picture);
  }

  async function saveStore() {
    let imageURL = "";

    if (image && image.length) {
      const file = image[0];
      const imageRef = storage.ref(`stores/${Date.now()}`);
      const imageSnapshot = await imageRef.put(file);
      imageURL = `https://firebasestorage.googleapis.com/v0/b/${imageSnapshot.metadata.bucket}/o/stores%2F${imageSnapshot.metadata.name}?alt=media`;
    }

    const data = {
      name: formState.values.name,
      description: formState.values.description,
      fee_per_sale: 0.1,
      avatar: imageURL,
      directors: formState.values.directors,
      contacts: formState.values.contacts,
      email: formState.values.email,
      cnpj: formState.values.cnpj,
      street: formState.values.street,
      number: formState.values.number,
      neighborhood: formState.values.neighborhood,
      city: formState.values.city,
      state: formState.values.state,
      postal_code: formState.values.postal_code.replace(/\D+/g, ""),
    };

    api
      .post(`/stores`, data)
      .then(() => {
        alert("Loja criada com sucesso!");
        history.push(`/stores`);
      })
      .catch(() => {
        alert("Ocorreu um erro, confira os dados e tente novamente");
      });
  }

  function fetchAdressByPostalCode() {
    cep(formState.values.postal_code).then((response) => {
      formState.setField("street", response.street);
      formState.setField("neighborhood", response.neighborhood);
      formState.setField("city", response.city);
      formState.setField("state", response.state);
    });
  }

  React.useEffect(fetchAdressByPostalCode, [formState.values.postal_code]);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={saveStore}>
            Salvar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Informações
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            {...text("name")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Slogan ou frase em destaque"
            variant="outlined"
            fullWidth
            multiline
            {...text("description")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Responsável (diretor ou representante comercial)"
            variant="outlined"
            fullWidth
            {...text("directors")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Telefone"
            variant="outlined"
            fullWidth
            {...text("contacts")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            {...text("email")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="CNPJ"
            variant="outlined"
            fullWidth
            {...text("cnpj")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="CEP"
            variant="outlined"
            fullWidth
            {...text("postal_code")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Rua"
            variant="outlined"
            fullWidth
            {...text("street")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Número"
            variant="outlined"
            fullWidth
            {...text("number")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Bairro"
            variant="outlined"
            fullWidth
            {...text("neighborhood")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Cidade"
            variant="outlined"
            fullWidth
            {...text("city")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Estado"
            variant="outlined"
            fullWidth
            {...text("state")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Imagens
          </Typography>
        </Grid>

        <ImageUploader
          withIcon={true}
          onChange={handleImageDrop}
          imgExtension={[".jpg", ".jpeg", ".png"]}
          maxFileSize={5 * 1024 * 1024}
          label="Tamanho máximo da imagem: 5mb, formatos: jpg, jpeg ou png"
          buttonText="Escolher imagem"
          singleImage
          withPreview
          // defaultImages={product?.images?.map((image) => image.src)}
        />
      </Grid>
    </Shell>
  );
}

export default View;
