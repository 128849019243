import React from "react";
import api from "../services/api";
import { makeStyles } from "@material-ui/core/styles";
import { useFormState } from "react-use-form-state";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
}));

function View({ match, history }) {
  const classes = useStyles();
  const [formState, { text }] = useFormState();

  function saveCategory() {
    const data = {
      name: formState.values.name,
      description: formState.values.description,
    };

    api
      .post(`/categories`, data)
      .then(() => {
        alert("Categoria criada com sucesso!");
        history.push(`/categories`);
      })
      .catch(() => {
        alert("Ocorreu um erro, confira os dados e tente novamente");
      });
  }

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={saveCategory}>
            Salvar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Informações
          </Typography>
        </Grid>

        <Grid item sm={12} md={8}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            {...text("name")}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            multiline
            {...text("description")}
          />
        </Grid>
      </Grid>
    </Shell>
  );
}

export default View;
