import axios from "axios";
import { getAuthData } from "./auth";

export const baseURL = "https://compre-nosso-server.onrender.com/";

const api = axios.create({ baseURL });

api.interceptors.request.use(async (config) => {
  const token = getAuthData()?.session?.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
