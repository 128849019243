import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";

// import Home from "./views/Home";
import CategoryCreate from "./views/CategoryCreate";
import CategoryEdit from "./views/CategoryEdit";
import CategoryList from "./views/CategoryList";
import CategoryView from "./views/CategoryView";
import OrderList from "./views/OrderList";
// import OrderView from "./views/OrderView";
import ProductCreate from "./views/ProductCreate";
import ProductEdit from "./views/ProductEdit";
import ProductList from "./views/ProductList";
import ProductView from "./views/ProductView";
import StoreCreate from "./views/StoreCreate";
import StoreEdit from "./views/StoreEdit";
import StoreActivatePayment from "./views/StoreActivatePayment";
import StoreList from "./views/StoreList";
import StoreView from "./views/StoreView";
import Login from "./views/Login";

function PrivateRoute({ component: Component, ...args }) {
  return (
    <Route
      {...args}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function Routes() {
  return (
    <Switch>
      <Route path="/signin" component={Login} />

      {/* <PrivateRoute path="/Home" exact component={Home} /> */}

      <PrivateRoute path="/categories/new" exact component={CategoryCreate} />
      <PrivateRoute
        path="/categories/:categoryId/edit"
        exact
        component={CategoryEdit}
      />
      <PrivateRoute path="/categories" exact component={CategoryList} />
      <PrivateRoute
        path="/categories/:categoryId"
        exact
        component={CategoryView}
      />

      <PrivateRoute path="/orders" exact component={OrderList} />
      {/* <PrivateRoute path="/orders/:orderId" exact component={OrderView} /> */}

      <PrivateRoute path="/products/new" exact component={ProductCreate} />
      <PrivateRoute
        path="/products/:productId/edit"
        exact
        component={ProductEdit}
      />
      <PrivateRoute path="/products" exact component={ProductList} />
      <PrivateRoute path="/products/:productId" exact component={ProductView} />

      <PrivateRoute path="/stores/new" exact component={StoreCreate} />
      <PrivateRoute path="/stores/:storeId/edit" exact component={StoreEdit} />
      <PrivateRoute
        path="/stores/:storeId/activate-payment"
        exact
        component={StoreActivatePayment}
      />
      <PrivateRoute path="/stores" exact component={StoreList} />
      <PrivateRoute path="/stores/:storeId" exact component={StoreView} />

      <Redirect path="*" to="/products" />
    </Switch>
  );
}

export default Routes;
