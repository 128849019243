import React from "react";
import api from "../services/api";
import formatMoney from "../utils/formatMoney";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";

import Shell from "../components/Shell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "flex-end",
    marginBottom: 16,
  },
  imageList: {
    display: "flex",
  },
  image: {
    maxHeight: 200,
  },
}));

const genders = { f: "Feminino", m: "Masculino", nd: "Neutro" };

function View({ match, history }) {
  const classes = useStyles();
  const [product, setProduct] = React.useState(null);

  function fetchContent() {
    api.get(`/products/${match.params.productId}`).then((response) => {
      setProduct(response.data);
    });
  }

  function editProduct() {
    history.push(`/products/${match.params.productId}/edit`);
  }

  function deleteProduct() {
    if (
      window.confirm(
        `Tem certeza que deseja excluir o produto ${product?.name}?`
      )
    ) {
      api.delete(`/products/${match.params.productId}`).then(() => {
        alert("Produto excluído com sucesso!");
      });
    }
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Button variant="contained" onClick={deleteProduct}>
            Excluir Produto
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={editProduct}>
            Editar Produto
          </Button>
        </Grid>
      </Grid>

      {product && (
        <>
          <Typography variant="h4" component="h2" gutterBottom>
            {product.name}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <b>Preço base:</b> {formatMoney(product.price)}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Descrição:</b> {product.description}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>REF:</b> {product.reference}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Loja:</b> {product.store?.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Categoria:</b> {product.category?.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gênero:</b> {genders?.[product.gender]}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Grade:</b> {product.grid}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Composição:</b> {product.materials}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Quantidade da grade:</b> {product.grid_quantity}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Ultima atualização:</b>{" "}
            {dayjs(product.updated_at).format("DD/MM/YYYY")}
          </Typography>

          <Typography variant="h5" gutterBottom>
            Cores disponíveis
          </Typography>
          {product.colors?.map((color) => (
            <Typography key={color.id} variant="body1" gutterBottom>
              <b>{color.label}</b>
            </Typography>
          ))}

          <Typography variant="h5" gutterBottom>
            Tamanhos disponíveis
          </Typography>
          {product.sizes?.map((size) => (
            <Typography key={size.id} variant="body1" gutterBottom>
              <b>{size.label}</b>: {size.price && formatMoney(size.price)}
            </Typography>
          ))}

          <Typography variant="h5" gutterBottom>
            Miniatura
          </Typography>
          <img className={classes.image} src={product.thumbnail} alt="" />

          <Typography variant="h5" gutterBottom>
            Imagens
          </Typography>
          <div className={classes.imageList}>
            {product.images?.map((image) => (
              <img
                key={image.id}
                className={classes.image}
                src={image.src}
                alt=""
              />
            ))}
          </div>
        </>
      )}
    </Shell>
  );
}

export default View;
