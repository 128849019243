import React from "react";
import api from "../services/api";
import formatMoney from "../utils/formatMoney";
import dayjs from "dayjs";

import Shell from "../components/Shell";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import TableRowLink from "../components/TableRowLink";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    justifyContent: "space-between",
    marginBottom: 16,
  },
  table: {
    minWidth: 650,
    position: "relative",
  },
  tableRow: {
    cursor: "pointer",
  },
}));

function View({ history }) {
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);

  function fetchContent() {
    api.get(`/products`).then((response) => {
      setProducts(response.data);
    });
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item>
          <Typography variant="h5" component="h2" gutterBottom>
            Produtos
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" href="/products/new">
            Adicionar Produto
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Preço (R$)</TableCell>
              <TableCell>REF</TableCell>
              <TableCell>Última alteração</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id} className={classes.tableRow} hover>
                <TableCell component="th" scope="row">
                  {product.id}
                </TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell align="right">
                  {formatMoney(product.price)}
                </TableCell>
                <TableCell>{product.reference}</TableCell>
                <TableCell>
                  {dayjs(product.updated_at).format("DD/MM/YYYY")}
                </TableCell>

                <TableRowLink href={`/products/${product.id}`} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Shell>
  );
}

export default View;
