import React from "react";
import api from "../services/api";

import Shell from "../components/Shell";

function View({ location, history, match }) {
  function activatePayment() {
    const searchParams = new URLSearchParams(location.search);
    const notificationCode = searchParams.get("notificationCode");
    const { storeId } = match.params;

    if (!notificationCode) history.push(`/stores`);

    const data = { notificationCode, storeId };

    api
      .post(`store-payment`, data)
      .then(() => {
        history.push(`/stores/${storeId}`);
      })
      .catch(() => {
        history.push(`/stores`);
      });
  }

  React.useEffect(activatePayment, []);

  return <Shell>Aguarde um momento...</Shell>;
}

export default View;
