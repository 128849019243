import * as firebase from "firebase/app";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyD5_syzv0R9Z820_EU-4PEuNdvvIWzd2kQ",
  authDomain: "compre-nosso.firebaseapp.com",
  projectId: "compre-nosso",
  storageBucket: "compre-nosso.appspot.com",
  messagingSenderId: "578688151927",
  appId: "1:578688151927:web:247c36c73c92d35777f172",
  measurementId: "G-NY6JVBTH1W",
};

export const app = firebase.initializeApp(config);

export const storage = firebase.storage();
