import styled from "styled-components";

const TableRowLink = styled.a`
  display: block;
  width: 100%;
  height: 53px;
  position: absolute;
  left: 0;
`;

export default TableRowLink;
