import React from "react";
// import api from "../services/api";
// import formatMoney from "../utils/formatMoney";
// import dayjs from "dayjs";

import Shell from "../components/Shell";
// import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";

// const useStyles = makeStyles((theme) => ({
//   buttonRow: {
//     justifyContent: "flex-end",
//     marginBottom: 16,
//   },
//   table: {
//     minWidth: 650,
//   },
//   tableRow: {
//     cursor: "pointer",
//   },
// }));

function View({ history }) {
  // const classes = useStyles();
  // const [orders, setOrders] = React.useState([]);

  // function fetchContent() {
  //   api.get(`/orders`).then((response) => {
  //     setOrders(response.data);
  //   });
  // }

  // function openListItem(item) {
  //   history.push(`/orders/${item.id}`);
  // }

  // React.useEffect(fetchContent, []);

  return (
    <Shell>
      <Typography variant="h4" component="h2" gutterBottom>
        Esta lista está vazia
      </Typography>
      {/* 
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Preço (R$)</TableCell>
              <TableCell>REF</TableCell>
              <TableCell>Última alteração</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow
                key={order.id}
                className={classes.tableRow}
                onClick={() => openListItem(order)}
                hover
              >
                <TableCell component="th" scope="row">
                  {order.id}
                </TableCell>
                <TableCell>{order.name}</TableCell>
                <TableCell align="right">
                  {formatMoney(order.price)}
                </TableCell>
                <TableCell>{order.reference}</TableCell>
                <TableCell>
                  {dayjs(order.updated_at).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Shell>
  );
}

export default View;
